import {
    RESET_HISTORY,
    REQUEST_BET_HISTORY
} from "@actions/actionTypes";


export function getHistory(limit, page){
    return dispatch => {
        const body = {
            limit: limit,
            page: page,
        }
        dispatch({
            type: REQUEST_BET_HISTORY,
            data: body
        })
    }
}

export function resetHistory() {
    return dispatch => {
        dispatch({type: RESET_HISTORY});
    }
}