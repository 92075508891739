import {DefaultLanguageCode} from "@constants/Enums";

export default {
    settings: {
        lang: DefaultLanguageCode
    },
    emptyObj: {},
    emptyArr: [],
    globalInfo: {
        isConnected: false,
        session:"",
        lobbyUrl: "",
        detectNewLogin: false
    },
    totalBets: 0,
    winnerList: [], //getWinnerList(),
    history: {
        data: [],
        hasNext: false,
    },
    odds: {
        _1_5 : "3.5",
        _11_15: "5.5",
        _6_10: "4.5",
        _16_25: "4.5",
        _26_30: "15",
        _31_35: "25",
        _36_40: "50",
        _41_49: "120",
        andar: "2",
        bahar: "1.9"

    },

    userBetList: [
        // {BetEvent: 1, Stake :0},
        // {BetEvent: 0, Stake :0},
        // {BetEvent: 2, Stake :0},
        // {BetEvent: 3, Stake :0},
        // {BetEvent: 4, Stake :0},
        // {BetEvent: 5, Stake :0},
        // {BetEvent: 6, Stake :0},
        // {BetEvent: 7, Stake :0},
        // {BetEvent: 8, Stake :0},
        // {BetEvent: 9, Stake :0},
        // {BetEvent: 10, Stake :0},
    ],

    cardsAndar: [
    ],
    cardsBahar: [
       
    ],
    cardsJoker: [
       
    ],
    cards: [],
    enabledBetAreas: false,
    timeTicker: {
        diff: 0,
        start: 0,
        end: 0
    },
    gameHistory: {
        history: [],
        APercentage: 0,
        BPercentage: 0,
        DrawPercentage: 0,
    },
    userInfo: {
        Balance: 0,
        Currency: "",
        Id: "",
        Image: null,
        Name: ""
    },
    limit: {
        Max: 0,
        Min: 0,
        Nominal: []
    },
    selectedBoard: {
        board: -1,
        odd:0
    },
    inactivity: {
        count : 0,
        suspend: false
    }
}

function getWinnerList() {
    let result = [];
    for (let i = 0; i < 100; i++) {
        result.push({
            PlayerName: (Math.random() + 1).toString(36).substring(Math.random() * 10) + i,
            WonAmount: Math.round(Math.random() * 100000)
        })
    }
    return result
}