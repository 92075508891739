import { SUB_VIDEO_STREAM, SET_VIDEO_STREAM_CONFIG } from "@actions/actionTypes";

export function subscribeVideoStream() {
    return dispatch => {
        dispatch({
            eventOn: SUB_VIDEO_STREAM,
            handle: (data) => {
                dispatch({type: SET_VIDEO_STREAM_CONFIG, config: data});
            }
        })
    }
}