import React, {useEffect, useState} from "react";
import {gameHistory} from "@constants/Enums";
import {HistoryTotalIcon} from "@resource";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";

function GameHistoryHeader({history}) {
    const [total, setTotal] = useState(0)
    const [winAnder, setWinAndar] = useState(0)
    const [winBahar, setWinBahar] = useState(0)
    useEffect(() => {
        let winAnder = 0,
            winBahar = 0;
        history.forEach(item => {
            if (item.WinningBetEvents[0] === gameHistory.A) {
                winAnder++
            }

            if (item.WinningBetEvents[0] === gameHistory.B) {
                winBahar++
            }
        })
        setTotal(history.length);
        setWinAndar(winAnder);
        setWinBahar(winBahar);
    }, [history]);

    return (
        <div className="game-history-header">
            <ul className="list">
                <li className="list-item">
                   <div className="icon"><HistoryTotalIcon /></div> {total}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory.A} type="small"/> {winAnder}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory.B} type="small"/> {winBahar}
                </li>
            </ul>
        </div>
    )
}

export default GameHistoryHeader