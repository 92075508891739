import initialStore from "../initialStore";
import {
    SET_HISTORY,
    RESET_HISTORY
} from "@actions/actionTypes";

export default function totalBetsReducer(state = initialStore.history, action) {
    switch (action.type) {
        case SET_HISTORY:
            return {data: [].concat(state.data, action.items), hasNext: action.items.length  }
        case RESET_HISTORY:
            return initialStore.history
        default:
            return state;
    }
}
