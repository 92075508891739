import React from "react";

export default function PrevButtonIcon() {
    return(
      <svg className="prev-button" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 26.666">
      <defs>
        <filter id="VectorPrevButton" x="2" y="1.333" width="20" height="25.333" filterUnits="userSpaceOnUse">
          <feOffset dy="2" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="2" result="blur"/>
          <feFlood/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g id="icon_outlined_directional_play-circle"  opacity="0.4">
        <rect id="icon_outlined_directional_play-circle_clip_mask_" width="24" height="24" fill="none"/>
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#VectorPrevButton)">
          <path id="Vector-2" d="M13.236.66,7.017,7.846a.475.475,0,0,1-.7,0L.1.66A.406.406,0,0,1,.447,0H12.886A.406.406,0,0,1,13.236.66Z" transform="translate(16 5.33) rotate(90)" fill="#878794"/>
        </g>
      </g>
    </svg>
    )
}

