import React, {useEffect, useState, useContext} from "react";
import classNames from "classnames";
import {gameHistory} from "@constants/Enums";
import {ResizeContext} from "./../../contexts/resizeContext";

function GameHistoryTable({history, limit}) {
    const [row, setRow] = useState(new Array(14).fill(""));
    const [col] = useState(new Array(6).fill(""))
    const [orderedHistory, setOrderedHistory] = useState([])
    const {smallPc, tab, mobile, middlePc} = useContext(ResizeContext);
    useEffect(() => {
        const ordered = [];
        let sameValue = [];
        history.forEach((item, index) => {
            const prevRound = history[index - 1];
            if (index === 0) {
                sameValue.push(item)
            } else if (item.WinningBetEvents[0] === prevRound.WinningBetEvents[0]) {
                sameValue.push(item)
            } else {
                ordered.push(sameValue);
                sameValue = [item]
            }
        })
        setOrderedHistory(ordered)
    }, [history])

    useEffect(() => {
        if (smallPc || tab || mobile || middlePc) {

            setRow(new Array(13).fill(""))
        }
    }, [smallPc, tab, mobile, middlePc])
    
    return (
        <div className="game-history-table">
            {
                row.map((_, indexRow) => (
                    <div className="game-history-table-row" key={`row-${indexRow}`}>
                        {
                            col.map((_, indexCol) => {
                                const count = orderedHistory[indexRow] && orderedHistory[indexRow].length > col.length ?  orderedHistory[indexRow].length : 0
                                return (
                                    <div className="game-history-table-col" key={`col-${indexCol}`}>
                                        <div  className={classNames("element",
                                            orderedHistory[indexRow] && orderedHistory[indexRow][indexCol] && orderedHistory[indexRow][indexCol].WinningBetEvents[0] ? gameHistory[orderedHistory[indexRow][indexCol].WinningBetEvents[0]] : "",
                                            indexCol === col.length - 1 && count ? "fill" : ""
                                        )}>
                                            {indexCol === col.length - 1 && count ? count : ""}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default GameHistoryTable