// export const DefaultLanguage = 'en-US';
export const DefaultLanguage = 'en';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];


export const BetsType = {
    1: "win",
    2: "lose"
}

export const TimeTicker = {
    6: "yellow",
    3: "red",
    0: "white"
}




export const SocketUpdateType = {
    0: "Ping",
    1: "Error",
    2: "RoundStart",
    3: "Card",
    4: "Odd",
    5: "Timer",
    6: "Result",
    7: "Bet",
    8: "Pair"
}
export const CardsSuit = {
    0: "spades", // for joker
    1: "clubs",
    2: "hearts",
    3: "diamonds"
}

export const CardHolderType = {
    "Undefined": 0,
    "Joker": 1,
    "Andar": 2,
    "Bahar": 3,
    0: "Undefined",
    1: "Joker",
    2: "Andar",
    3: "Bahar"
}

export const PlayerOddType = {
    0: "None",
    1: "PlayerA",
    2: "PlayerB",
    3: "Both",
    "None" : 0,
    "PlayerA": 1,
    "PlayerB": 2,
    "Both": 3
}

export const Prediction = {
    "_1_5": 0,
    "_6_10": 1,
    "_11_15": 2,
    "_16_25": 3,
    "andar": 4,
    "joker": 5,
    "bahar": 6,
    "_26_30": 7,
    "_31_35": 8,
    "_36_40": 9,
    "_41_49": 10,
    0: "_1_5",
    1: "_6_10",
    2:"_11_15",
    3: "_16_25",
    4: "andar",
    5: "joker",
    6: "bahar",
    7: "_26_30",
    8: "_31_35",
    9:  "_36_40",
    10: "_41_49"
 
}

export const gameHistory = {
    0: "J", 
    1: "A", 
    2: "B", 
    "J": 0,
    "A": 1,
    "B": 2
}


export const CardSide = {
    0: "right",
    1: "center",
    2: "left"
}

export const CardName = [
    "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"
]

export const CardTypeMap = {
    1: "A",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "J",
    12: "Q",
    13: "K",
    14: "Joker1",
    15: "Joker2"
}

export const CardType = {
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 7,
    "8": 8,
    "9": 9,
    "10": 10,
    "J": 11,
    "Q": 12,
    "K": 13,
    "A": 1,
    "Joker1": 15,
    "Joker2": 16
}

export const SocketReadyState = {
    "CONNECTING": 0,
    "OPEN": 1,
    "CLOSING": 2,
    "CLOSED": 3
}