export function getLocalStorage(key) {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) ) : false
}

export function setLocalStorage(key ,value) {
    localStorage.setItem(key, JSON.stringify(value))
}

export function removeLocalStorage(key) {
    localStorage.removeItem(key)
}


