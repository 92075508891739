import {
    SET_VIDEO_STREAM_CONFIG,
} from "@actions/actionTypes";

export default function videoStreamConfigReducer(state = "", action) {
    switch (action.type) {
        case SET_VIDEO_STREAM_CONFIG:
            return action.config;
        default:
            return state;
    }
}