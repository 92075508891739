import React, { useEffect, useState } from "react";
import ToolTip from "@components/toolTip/toolTip";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";
import RoundInfoItem from "@components/history/RoundHistory/RoundInfoItem";
import CardSlider from "@components/cards/cardsSlider";
import { FormattedMessage } from "react-intl";
import { CardsSuit, PlayerOddType, CardTypeMap } from "@constants/Enums";
import { ModalCloseIcon } from "@resource";

function GameHistoryLastResult({ round, ...restProps }) {
    const [activeRound, setActiveRound] = useState(false)
    const [playerACards, setPlayerACards] = useState([])
    const [playerBCards, setPlayerBCards] = useState([])
    const [playerJCards, setPlayerJCards] = useState([])

    useEffect(() => {
        if (round.RoundCards) {
            let cardsA = [], cardsB = [], cardsJ = [];
            round.RoundCards.forEach((card, index) => {
                if (card.CardHolder === 1) {
                    cardsJ.push(CardTypeMap[card.Card.Number] + CardsSuit[card.Card.Suit])
                }
                if (card.CardHolder === 2) {
                    cardsA.push(CardTypeMap[card.Card.Number] + CardsSuit[card.Card.Suit])
                }
                if (card.CardHolder === 3) {
                    cardsB.push(CardTypeMap[card.Card.Number] + CardsSuit[card.Card.Suit])
                }
            })
            setPlayerACards(cardsA)
            setPlayerBCards(cardsB)
            setPlayerJCards(cardsJ)
        }
    }, [round])

    const trigger = (isActive) => {
        setActiveRound(isActive)
    }

    const getContent = () => {
        return (
            <div className="game-history-last-results-item">
                <div className="game-history-last-results-item-header">
                    <div className="title">
                        <FormattedMessage id="round_id" />: {round.RoundNumber}
                    </div>
                    <button className="circled-button small">
                        <ModalCloseIcon id={`GameHistoryLastResult${round.RoundNumber}`} />
                    </button>
                </div>
                <div className="game-history-last-results-item-round-info">
                    <RoundInfoItem time={round.RoundStartDate}
                        timeTitle="game_start" />
                    <RoundInfoItem time={round.RoundEndDate}
                        timeTitle="game_end" />
                </div>
                <div className="game-history-last-results-item-round-cards">
                    <div className="cards" style={{position:"relative"}}>
                        <div className="cards-andar">
                            <div className="cards-history">
                                <span className="card-name">
                                    <FormattedMessage id="andar" />
                                </span>
                                <span className="card-sum">
                                </span>
                            </div>
                            <CardSlider cardList={playerACards} cardsName="andar" uiPosition="cards" />
                        </div>
                        <div className="cards-joker">
                            <div className="cards-history">
                                <span className="card-name">
                                    <FormattedMessage id="joker" />
                                </span>
                                <span className="card-sum">
                                </span>
                            </div>
                            <CardSlider cardList={playerJCards} cardsName="joker" uiPosition="cards" />
                        </div>
                        <div className="cards-bahar">
                            <div className="cards-history">
                                <span className="card-name">
                                    <FormattedMessage id="bahar" />
                                </span>
                                <span className="card-sum">
                                </span>
                            </div>
                            <CardSlider cardList={playerBCards} cardsName="bahar" uiPosition="cards" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <ToolTip title={getContent()} position={"top"} /*type="popup"*/ triggerCallBack={trigger}{...restProps}>
                <GameHistoryIcon won={round.WinningBetEvents[0]} type="large" round={round} active={activeRound} />
            </ToolTip>
        </>

    )
}

export default GameHistoryLastResult