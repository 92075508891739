import {
    SET_JOKER_CARD,
    SET_ALL_JOKER_CARD,
    RESET_CARDS
} from "@actions/actionTypes";
import initialStore from "../../initialStore";

export default function cardsJokerReducer(state = initialStore.cardsJoker, action) {
    switch (action.type) {
        case SET_JOKER_CARD:
            return [...state, action.card]
        case SET_ALL_JOKER_CARD:
            return [...action.card]
        case RESET_CARDS:
            return initialStore.cardsJoker
        default:
            return state;
    }
}