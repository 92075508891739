import React, {useState, useContext} from "react";
import BurgerMenu from "./burgerMenu";
import CloseGame from "./closeGame";
import FullScreen from "./fullScreen";
import GameMusic from "./gameMusic";
import GameRules from "./gameRules";
import GameSound from "./gameSound";
import PlayerHistory from "./playerHistory";
import SwitchView from "./switchView";
import {ResizeContext} from "../../contexts/resizeContext";

function HeaderActions() {
    const {mobile, tab} = useContext(ResizeContext);

    const [mobileActions, setMobileActions] = useState("");   

    return (
        <div className="settings-box">
            <ul className="template-button-list">
                {!mobile && <FullScreen />}
                {!mobile && <SwitchView />}
                <PlayerHistory mobileActions={mobileActions} setMobileActions={setMobileActions} />
                <GameRules mobileActions={mobileActions} setMobileActions={setMobileActions} />
                <GameSound />
                <GameMusic/>
                {mobile && <BurgerMenu  setMobileActions={setMobileActions}/>}
                <CloseGame />
            </ul>
            
        </div>
    )
}

export default HeaderActions