import {getLocalStorage} from "@services/storageService";

export function abbrNum(number, decPlaces = 1) {
    let sum = number
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10,decPlaces);

    // Enumerate sum abbreviations
    var abbrev = [ "k", "m", "b", "t" ];

    // Go through the array backwards, so we do the largest first
    for (var i=abbrev.length-1; i>=0; i--) {

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10,(i+1)*3);

        // If the sum is bigger or equal do the abbreviation
        if(size <= sum) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            sum = Math.round(sum*decPlaces/size)/decPlaces;

            // Handle special case where we round up to the next abbreviation
            if((sum == 1000) && (i < abbrev.length - 1)) {
                sum = 1;
                i++;
            }

            // Add the letter for the abbreviation
            sum += abbrev[i];

            // We are done... stop
            break;
        }
    }

    return sum;
}

export function getActiveBetsSum(activeBets) {
    let betsSum  = 0;
    activeBets.forEach(bet => {
        if (bet.accepted) {
            betsSum +=  bet.Stake
        }

    })
    return betsSum
}

export function getBetsList(newBet, existingBets) {
    let betList = JSON.parse(JSON.stringify(existingBets)) || [];
    const index = betList.findIndex(bet => bet.BetEvent === newBet.BetEvent);

    if (index > -1) {
        betList = betList.map(bet => {
            if (bet.BetEvent === newBet.BetEvent) {
                return  {...bet, Stake: bet.Stake + newBet.Stake}
            } else {
                return bet
            }

        });
    } else {
        betList.push(newBet)
    }

    return betList
}