import {
    SET_DISABLE_BETS_AREAS
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function enabledBetAreasReducer(state = initialStore.enabledBetAreas, action) {
    switch (action.type) {
        case SET_DISABLE_BETS_AREAS:
            return action.isEnabled
        default:
            return state;
    }
}
