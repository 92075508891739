import {SET_GAME_HISTORY} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function gameHistoryReducer(state = initialStore.gameHistory, action) {
    switch (action.type) {
        case SET_GAME_HISTORY:
            return {
                history : action.gameHistory,
                APercentage: action.gameHistory.APercentage,
                BPercentage: action.gameHistory.BPercentage,
                DrawPercentage: action.gameHistory.DrawPercentage
            }
        default:
            return state;
    }
}