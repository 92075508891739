import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { subscribeCards } from "@actions/cardsAction";
import {FormattedMessage} from "react-intl";
import CardSlider from "./cardsSlider";

function Cards({subscribeCards, cardsAndar, cardsBahar, cardsJoker}) {

    useEffect(() => {
        subscribeCards();
    }, [])
 
    return (
        <div className="cards">
            <div className="cards-andar">
                <div className="cards-history">
                    <span className="card-name">
                        <FormattedMessage id="andar" />
                    </span>
                    <span className="card-sum">
                    </span>
                </div>
                <CardSlider cardList={cardsAndar} cardsName="andar" uiPosition="cards" />
            </div>
            <div className="cards-joker">
                <div className="cards-history">
                    <span className="card-name">
                        <FormattedMessage id="joker" />
                    </span>
                    <span className="card-sum">
                    </span>
                </div>
                <CardSlider cardList={cardsJoker} cardsName="joker" uiPosition="cards"/>
            </div>
            <div className="cards-bahar">
                <div className="cards-history">
                    <span className="card-name">
                        <FormattedMessage id="bahar" />
                    </span>
                    <span className="card-sum">
                    </span>
                </div>
                <CardSlider cardList={cardsBahar} cardsName="bahar" uiPosition="cards" />
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        cardsAndar: state.cardsAndar,
        cardsBahar: state.cardsBahar,
        cardsJoker: state.cardsJoker
    }
}

const mapDispatchToProps = {
    subscribeCards
}


export default connect(mapStateToProps, mapDispatchToProps)(Cards);