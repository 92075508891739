import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { getDateDDMMYYUtc } from "@services/timestampService";
import { numberWithSpaces } from "@services/numberFormatServices";

export function RoundInfoItem({time, timeTitle, balance, balanceTitle, intl}) {
    
    return (
        <div className="round-info-item">
            <div>
                <span className="title"><FormattedMessage id={timeTitle}/>:</span>
                <span className="time">{getDateDDMMYYUtc(time, intl, "dd-mm-yy-time-am-pm" )}</span>
            </div>
            <div>
                {/* {balanceTitle ?  <span className="bold-title"><FormattedMessage id={balanceTitle} />:</span> : "" }
                {balance ? <span className="balanc">{numberWithSpaces(balance)}</span> : ""} */}
            </div>
        </div>
    )
}
export default  injectIntl(RoundInfoItem);