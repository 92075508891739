import { call, put, takeLatest } from "redux-saga/effects";
import {
    REQUEST_AVAILABLE_LANGUAGES,
    SET_AVAILABLE_LANGUAGES,
    REQUEST_SELECTED_LANGUAGE_MESSAGE,
    SET_SELECTED_LANGUAGE_MESSAGE, REQUEST_REFRESH_SESSION
} from "@actions/actionTypes";
import {
    GetResource, PostResource
} from "@utils/HttpUtil";
import {safe, onError} from "./handlerError";

function* fetchAvailableLanguages() {
    const languages = yield call(GetResource, `/rate/translations/langs`);
    yield put({type: SET_AVAILABLE_LANGUAGES, languages: languages.items});
}

function* fetchSelectedLanguageMessage({langId}) {
    const languages = yield call(GetResource, `/rate/translations?langid=${langId}`);
    yield put({type: SET_SELECTED_LANGUAGE_MESSAGE, language: languages.items});
}

function* fetchRefreshSession({session}) {
    const refresh = yield call(PostResource, `/session/${session}/refresh`)
}

export default function* () {
    yield takeLatest(REQUEST_AVAILABLE_LANGUAGES, safe(onError, fetchAvailableLanguages));
    yield takeLatest(REQUEST_SELECTED_LANGUAGE_MESSAGE, safe(onError, fetchSelectedLanguageMessage));
    yield takeLatest(REQUEST_REFRESH_SESSION, safe(onError, fetchRefreshSession))
}