import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CardsSuit, Prediction, PlayerOddType, BetsType, CardTypeMap } from "@constants/Enums";

import RoundInfoItem from "./RoundInfoItem";
import CardList from "./CardList";
import { floatNumberWithSpaces } from "@services/numberFormatServices";
import CardSlider from "@components/cards/cardsSlider";

export function RoundHistory({ round, getHistoryItemValue }) {
    const [playerACards, setPlayerACards] = useState([])
    const [playerBCards, setPlayerBCards] = useState([])
    const [playerJCards, setPlayerJCards] = useState([])

    useEffect(() => {
        if (round.roundCards) {
            let cardsA = [], cardsB = [], cardsJ = [];
            round.roundCards.forEach((card, index) => {
                if (card.cardHolder === 1) {
                    cardsJ.push(CardTypeMap[card.card.number] + CardsSuit[card.card.suit])
                }
                if (card.cardHolder === 2) {
                    cardsA.push(CardTypeMap[card.card.number] + CardsSuit[card.card.suit])
                }
                if (card.cardHolder === 3) {
                    cardsB.push(CardTypeMap[card.card.number] + CardsSuit[card.card.suit])
                }
            })
            setPlayerACards(cardsA)
            setPlayerBCards(cardsB)
            setPlayerJCards(cardsJ)
        }
    }, [round])

    return (
        <div className="history-round scrollable">
            <div className="history-round-info">
                <RoundInfoItem time={round.startTime}
                    timeTitle="game_start"
                    balance={round.balanceBefore}
                    balanceTitle="balance_before_the_round" />
                <RoundInfoItem time={round.endTime}
                    timeTitle="game_end"
                    balance={round.balanceAfter}
                    balanceTitle="balance_after_the_round" />
            </div>
            <div className="round-cards">
                <div className="cards" style={{ position: "relative" }}>
                    <div className="cards-andar">
                        <div className="cards-history">
                            <span className="card-name">
                                <FormattedMessage id="andar" />
                            </span>
                            <span className="card-sum">
                            </span>
                        </div>
                        <CardSlider cardList={playerACards} cardsName="andar" uiPosition="cards" />
                    </div>
                    <div className="cards-joker">
                        <div className="cards-history">
                            <span className="card-name">
                                <FormattedMessage id="joker" />
                            </span>
                            <span className="card-sum">
                            </span>
                        </div>
                        <CardSlider cardList={playerJCards} cardsName="joker" uiPosition="cards" />
                    </div>
                    <div className="cards-bahar">
                        <div className="cards-history">
                            <span className="card-name">
                                <FormattedMessage id="bahar" />
                            </span>
                            <span className="card-sum">
                            </span>
                        </div>
                        <CardSlider cardList={playerBCards} cardsName="bahar" uiPosition="cards" />
                    </div>
                </div>
            </div>
            <div className="round-bets">
                <div className="tbl">
                    <div className="row">
                        <div className="cell"><FormattedMessage id="bet_type" /></div>
                        <div className="cell"><FormattedMessage id="bet" /></div>
                        <div className="cell"><FormattedMessage id="odd" /></div>
                        <div className="cell"><FormattedMessage id="win_lose" /></div>
                    </div>
                    {
                        round.bets.map((item, index) => (
                            <div className="row" key={index}>
                                <div className="cell">
                                    {/* <FormattedMessage id={Prediction[item.prediction]} /> */}
                                </div>
                                <div className="cell">{floatNumberWithSpaces(item.stake)}</div>
                                <div className="cell">{floatNumberWithSpaces(item.odd)}</div>

                                <div className="cell">{floatNumberWithSpaces(getHistoryItemValue(item), 0)}</div>
                            </div>
                        ))
                    }
                    <div className="row">
                        <div className="cell"><FormattedMessage id="total" />:</div>
                        <div className="cell">{floatNumberWithSpaces(round.bets.reduce((a, b) => b.stake + a, 0))}</div>
                        <div className="cell"></div>
                        <div className="cell">{floatNumberWithSpaces(round.bets.reduce((a, b) => getHistoryItemValue(b) + a, 0), 0)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RoundHistory;