import React from "react";
// import CardsList from "@components/Cards/CardsList";
import classNames from "classnames";

function CardList({player, cards, winner = false}) {
    return (
        <div className={classNames("history-card-list", { active: winner})}>
            <div>
                <div className="player">{ player }</div>
            </div>
            <div>
                {/* <CardsList cardList={cards} className="history-card-list-view"/> */}
            </div>
        </div>
    )
}

export default CardList