import {createStore, applyMiddleware, compose} from "redux";
import rootReducer from "./reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant"
import thunk from "redux-thunk";
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga/index';
import socketMiddleware from "./middlewares/socketMiddleware"
import SocketClient from "@utils/SocketClient";
import workerMiddleware from "./middlewares/workerMiddleware";
import WorkerService from "@services/workerService";
import {SET_ALERT_MSG, SET_LOGIN_DETECTED, SET_SOCKET_CONNECTIONS} from "@actions/actionTypes";

const sagaMiddleware = createSagaMiddleware();


export default function configureStore(initialState) {
    const isDev = process.env.NODE_ENV === "development" || process.env.NODE_ENV === "env"
    const composeEnhancers =
        (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ) || compose; // add support for Redux dev tools

    const socket = SocketClient();
    const worker = new WorkerService();

    const middlewares = [
        thunk,
        sagaMiddleware,
        socketMiddleware(socket),
        workerMiddleware(worker)
    ];

    if (isDev) {
        middlewares.splice(1, 0, reduxImmutableStateInvariant());
    }

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    worker.dispatchAction = function (action) {
        store.dispatch(action)
    }

    socket.onOpen( function() {
        store.dispatch({type: SET_SOCKET_CONNECTIONS, isConnected: true});
        socket.pingSocket()
        console.log("connect")
    });

    socket.onClose( function() {
        store.dispatch({type: SET_SOCKET_CONNECTIONS, isConnected: false});
        console.log("disconnect")
    });

    socket.onConnectEnd(function (code) {
        if (code === 1008) {
            store.dispatch({type: SET_LOGIN_DETECTED,  detectNewLogin: true})
        } else {
            store.dispatch({type: SET_ALERT_MSG,  data: {type: 'error', messages: `error_${code}`}})
        }

    });

    sagaMiddleware.run(rootSaga);

    return store;
}
