import {
    SET_GAME_CONDITION,
    SET_ROUND_START,
    SET_TIME_TICKER,
    SUB_ROUND_START,
    SET_DISABLE_BETS_AREAS 
} from "@actions/actionTypes";
import {removeLocalStorage} from "@services/storageService";

export function subscribeRoundIdAction() {
    return dispatch => {
        const localstorageList = Object.entries(localStorage)

        dispatch({eventOn: SUB_ROUND_START,
            handle: (data) => {
                dispatch({type: SET_TIME_TICKER, ticker: {Start: data.BettingStartTime || 0, End: data.BettingEndTime || 0}});
                dispatch({type: SET_ROUND_START, roundId : data.RoundNumber});
                dispatch({type: SET_GAME_CONDITION, condition : true});
                dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: true})
                localstorageList.forEach(item => {
                    if (item[0].includes("Bets") && !item[0].includes(data.RoundId)) {
                        removeLocalStorage(item[0])
                    }
                })
            }
        });
    }
}