import {
    RESET_CARDS,
    SUB_GAME_FINISH,
    RESET_USER_BET_LIST,
    SET_GAME_CONDITION,
    SET_ROUND_START,
    SUB_CANCEL_GAME,
    SET_TIME_TICKER,
    SET_ALERT_MSG,
    REQUEST_REFRESH_SESSION,
    REQUEST_GAME_START,
    CONNECT_TO_SOCKET,
    SUB_GAME_INIT,
    SET_GAME_INITIAL_DATA,
    SET_ALL_BETS,
    SET_USER_INFO,
    SET_DISABLE_BETS_AREAS,
    SET_LIMIT,
    SET_USER_WON,
    SUB_GAME_RESULT,
    SET_WINNER_LIST,
    SET_ALL_ANDAR_CARD,
    SET_ALL_JOKER_CARD,
    SET_ALL_BAHAR_CARD,
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import {getLocalStorage, removeLocalStorage, setLocalStorage} from "@services/storageService";
import {CardsSuit, CardHolderType, CardTypeMap} from "@constants/Enums";


export function subscribeGameFinish() {
    return (dispatch, getState) => {
        dispatch({
            eventOn: SUB_GAME_FINISH,
            handle: () => {
                const {userInfo, roundId} = getState();
                removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`)
                removeLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`)
                dispatch({type: RESET_CARDS, card: []});
                dispatch({type: RESET_USER_BET_LIST});
                dispatch({type: SET_GAME_CONDITION, condition : false});
                dispatch({type: SET_ROUND_START, roundId : ""});
                dispatch({type: SET_ALL_BETS, bets: []});
                dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: false})
            }
        })
    }
}

export function subscribeGameResult() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_RESULT,
            handle: (data) => {
                dispatch({type: SET_USER_WON, wonAmount: {value: data.WonAmount}});
                dispatch({type: SET_WINNER_LIST, winners: data.Results});
            }
        })
    }
}

export function setUserWon(wonAmount) {
    return dispatch => {
        dispatch({type: SET_USER_WON, wonAmount})
    }
}

export function subscribeCanceledGame() {
    return (dispatch, getState) => {
        dispatch({
            eventOn: SUB_CANCEL_GAME,
            handle: (data) => {
                const {userInfo} = getState();
                dispatch({type: RESET_CARDS, card: []});
                dispatch({type: SET_ROUND_START, roundId : ""});
                dispatch({type: SET_TIME_TICKER, ticker: initialStore.timeTicker});
                dispatch({type: SET_ALERT_MSG, data: {type: 'info', messages: "game_is_canceled_your_bets_will_refund"}});
                dispatch({type: SET_ALL_BETS, bets: []});
                removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${data.roundId}`)
                removeLocalStorage(`acceptedBets_${userInfo.Id}_${data.roundId}`)
                dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: false})
            }
        })
    }
}

export function refreshSession(session) {
    return dispatch => {
        dispatch({
            type: REQUEST_REFRESH_SESSION,
            session
        })
    }
}

export function gameStart() {
    return dispatch => {
        dispatch({
            type: REQUEST_GAME_START,
        })
    }
}

export function connectToSocket(session) {
    return dispatch => {
        dispatch({
            type: CONNECT_TO_SOCKET,
            session
        })
    }
}

export function subGameInitData() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_INIT,
            handle: (data) => {
                let unAcceptedBets = [], bets = []
                if (data?.Round?.RoundCards && data.Round.RoundCards.length) {
                    const andarCards = [], baharCards = [], jokerCards = [];
                    data.Round.RoundCards.forEach(item => {
                        const card = CardTypeMap[item.Card.Number] + CardsSuit[item.Card.Suit];
                        if (CardHolderType.Joker === item.CardHolder) {
                            jokerCards.push(card)
                        }
                        if (CardHolderType.Andar === item.CardHolder) {
                            andarCards.push(card)
                        }
                        if (CardHolderType.Bahar === item.CardHolder) {
                            baharCards.push(card)
                        }  
                    })
                    dispatch({type: SET_ALL_JOKER_CARD, card: jokerCards});
                    dispatch({type: SET_ALL_ANDAR_CARD, card: andarCards});
                    dispatch({type: SET_ALL_BAHAR_CARD, card: baharCards});
                }
                dispatch({type: SET_TIME_TICKER, ticker: {Start: data.Round?.BettingStartTime || 0, End: data.Round?.BettingEndTime || 0}});
                dispatch({type: SET_GAME_INITIAL_DATA, ...data});
                dispatch({type: SET_USER_INFO, user: data.Player});
                dispatch({type: SET_LIMIT, limit: data.Limits});
                dispatch({type: SET_ROUND_START, roundId : data.Round.RoundNumber});
                if( data.Round?.BettingEndTime && new Date().valueOf() < data.Round.BettingEndTime) {
                    dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: true})
                }
                if (data.Player.Id && data.Round?.RoundId) {
                    unAcceptedBets = getLocalStorage(`unAcceptedBets_${data.Player.Id}_${ data.Round.RoundNumber}`) || []
                }
                if (data.PlayerBets?.Bets.length) {
                    data.PlayerBets.Bets.forEach(bet => {
                        bets.push({
                            accepted: true,
                            BetEvent: bet.BetEvent,
                            Stake: bet.Stake,
                            Odd: bet.Odd})
                    })
                }
                if (data.Round?.RoundNumber && data.Player?.Id) {
                    setLocalStorage(`acceptedBets_${data.Player.Id}_${data.Round.RoundNumber}`, bets)
                }
                dispatch({type: SET_ALL_BETS, bets: [...unAcceptedBets, ...bets], startTime:  data.Timer?.Start});
            }
        })
    }
}