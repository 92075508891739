import {
    SET_ANDAR_CARD,
    SET_JOKER_CARD,
    SUB_CARDS,
    SET_SOUND_KEY, 
    SET_BAHAR_CARD,
    RESET_CARDS
} from "@actions/actionTypes";
import {CardsSuit, CardHolderType, CardTypeMap} from "@constants/Enums";

export function subscribeCards() {
    return dispatch => {

        dispatch({
            eventOn: SUB_CARDS,
            handle: (data) => {
                const card = CardTypeMap[data.CardNumber] + CardsSuit[data.Suit];
                dispatch({type: SET_SOUND_KEY, payload: card})
                if (CardHolderType.Joker === data.CardHolder) {
                    dispatch({type: SET_JOKER_CARD, card});
                }
                if (CardHolderType.Andar === data.CardHolder) {
                    dispatch({type: SET_ANDAR_CARD, card});
                }
                if (CardHolderType.Bahar === data.CardHolder) {
                    dispatch({type: SET_BAHAR_CARD, card});
                }  
            }
        })
    }
}

export function resetPlayingCards() {
    return dispatch => {
        dispatch({type: RESET_CARDS, card: []})
    }
}