import React, { useState, useEffect } from "react";
import cardBg from "@assets/img/cardBg.svg";
import openedCardBg from "@assets/img/opendedCardBg.svg";
import classNames from "classnames";

function CardSliderItem({card, ...restProps}) {
    const [cardItem, setCarditem] = useState("")

    useEffect(() => {
        let timer = setTimeout(() => {
            setCarditem(card);
        }, 500)

        return () => {
            clearTimeout(timer);
        }
    }, [card])

    return(
        <div className="cards-list-item">
            <div className="card" {...restProps}>
                <div className={classNames("flip-card", {active: cardItem})}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img src={cardBg} alt={cardBg} />
                        </div>
                        <div className="flip-card-back">
                            <img src={openedCardBg} alt={openedCardBg} />
                            <i className={`cardIcon icon-${cardItem}`}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardSliderItem