import React, {useContext, useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {ResizeContext} from "../../contexts/resizeContext";
function ToolTipContent({title, toolTipParams, position, type, className})  {
    const [template, setTemplate] = useState(document.createElement('div'));
    const { mobile} = useContext(ResizeContext);
    const tooltipElement = useRef(document.getElementById('tooltip-root'))

    useEffect(() => {
        tooltipElement.current = document.getElementById('tooltip-root')
        var p = template;
        p.className="tooltip-content";

        if ( type === "popup") {
            p.addEventListener("click", (e) => {e.stopPropagation()})
        }
        tooltipElement.current?.appendChild(p)
        setTemplate(p);
        return () => {
            tooltipElement.current?.removeChild(p)
        }
    }, [])

    useEffect(() => {
        const scale = mobile ? 1 : +document.documentElement.style.getPropertyValue("--scale-value") || 1;
        const translateX = +document.documentElement.style.getPropertyValue("--translateX-value") || 0;
        const translateY = +document.documentElement.style.getPropertyValue("--translateY-value") || 0;
        const bottom =  (toolTipParams.bottom / scale - translateY) ;
        const top = (toolTipParams.top / scale - translateY) ;
        const left = (toolTipParams.left / scale - translateX);
        const right = (toolTipParams.right / scale - translateX);
        const width = toolTipParams.width;
        const height = toolTipParams.height;
        const arrowSizes = type === "popup" ? 17  : 15 ;
        template.querySelectorAll(".arrow").forEach(e => e.remove());
        const arrow = document.createElement("div")
        arrow.className = arrow.className + "arrow"
        var p = template;
        if (position) {
            p.className= `tooltip-content ${position} ${type} ${className}`
        }
        if (position === "left") {
            p.style.top = bottom - height   + 'px'
            p.style.bottom = "unset"
            p.style.right = 'unset';
            p.style.left = left - (p.clientWidth / 2)  - arrowSizes + 'px'
        } else if (position === "top") {
            p.style.top = top  - (p.clientHeight) - arrowSizes  + 'px'
            p.style.right = 'auto';
            p.style.left = Math.max(right - (width / 2) - (p.clientWidth / 2), 4) + 'px';
        } else if (position === "right") {
            p.style.top = bottom - height  + 'px'
            p.style.left = Math.max(right + (p.clientWidth / 2) + arrowSizes, 4) + 'px';
            p.style.right = 'unset';
        } else {
            p.style.left = Math.max((right - (width / 2)) - (p.clientWidth / 2), 4) + 'px'
            p.style.top = top + height + arrowSizes + 'px'
        }

        if (toolTipParams.right - (width / 2) + (p.clientWidth / 2) > window.innerWidth) {
            p.style.left = 'unset';
            p.style.right = `10px`
            arrow.style.right =  `${document.documentElement.clientWidth - (toolTipParams.right + (width /3)) }px`
        } else if(left - (width / 2) - (p.clientWidth / 2) < 0) {
            p.style.left =  mobile ? `4px` :`${left - (arrowSizes / 2)}px`;
            p.style.right = "unset"
            arrow.style.left =   mobile ? `${ left  - (width / 2) - 4}px`: `${(width / 2) - (arrowSizes / 2)}px`
        }
        p.appendChild(arrow)
        setTemplate(p);

    }, [template, toolTipParams])

    return ReactDOM.createPortal(
        title,
        template,
    );
}

export default ToolTipContent