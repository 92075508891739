import {
    SET_ANDAR_CARD,
    SET_ALL_ANDAR_CARD,
    RESET_CARDS
} from "@actions/actionTypes";
import initialStore from "../../initialStore";

export default function cardsAndarReducer(state = initialStore.cardsAndar, action) {
    switch (action.type) {
        
        case SET_ANDAR_CARD:
            return [...state, action.card]
        case SET_ALL_ANDAR_CARD:
                return [...action.card]
        case RESET_CARDS:
            return initialStore.cardsAndar
        default:
            return state;
    }
    
}
