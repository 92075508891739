import {call, put, takeLatest} from "redux-saga/effects";
import {GetResource} from "@utils/HttpUtil";
import {
    SET_RULES,
    REQUEST_RULES,
} from "@actions/actionTypes";
import {onError, safe} from "./handlerError";

function* fetchRules() {
    const rule = yield call(GetResource, `/Rules`, {});
    yield put({type: SET_RULES, rules:   rule });
}

export default function* () {
    yield takeLatest(REQUEST_RULES, safe(onError, fetchRules));
}