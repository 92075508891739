import {
    SET_BAHAR_CARD,
    SET_ALL_BAHAR_CARD,
    RESET_CARDS
    
} from "@actions/actionTypes";
import initialStore from "../../initialStore";

export default function cardsBaharReducer(state = initialStore.cardsBahar, action) {
    switch (action.type) {
        case SET_BAHAR_CARD:
            return [...state, action.card]
        case SET_ALL_BAHAR_CARD:
            return [...action.card]
        case RESET_CARDS:
            return initialStore.cardsBahar
        default:
            return state;
    }
}