import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import {  Navigation } from "swiper";
import CardsSliderItem from "./cardSliderItem";
import {PrevButtonIcon, NextButtonIcon} from "@resource";


function CardsSlider ({cardList, cardsName, uiPosition}) {

    return (
        <div className="cards-slider">
            {
                cardsName === 'joker' ? "" : <span className={`arrow-${cardsName}-${uiPosition}-prev`}>
                    <PrevButtonIcon />
                </span>
            }
        
            <Swiper
                slidesPerView={"auto"}
                modules={[Navigation]}
                navigation={{
                    prevEl: `.arrow-${cardsName}-${uiPosition}-prev`,
                    nextEl: `.arrow-${cardsName}-${uiPosition}-next`,
                }}>
                {cardList.map((card, index) => (
                    <SwiperSlide key={index}>
                        <CardsSliderItem card={card}/>
                    </SwiperSlide>
                ))}
            </Swiper>
            {
                cardsName === 'joker' ? "" : <span className={`arrow-${cardsName}-${uiPosition}-next`}>
                     <NextButtonIcon  />
                </span>
            }
        </div>
    )
}

export default CardsSlider