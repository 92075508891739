import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {subscribeGameHistory, getGameHistory} from "@actions/gameInfoAction";
import GameHistoryHeader from "@components/gameHistory/gameHistoryHeader";
import GameHistoryTable from "@components/gameHistory/gameHistoryTable";
import {FormattedMessage} from "react-intl";
import GameHistoryLastResult from "@components/gameHistory/gameHistoryLsatresult";

function GameHistory({ history, isConnected, subscribeGameHistory, gameCondition, getGameHistory, APercentage, BPercentage, DrawPercentage}) {
    const [limit] = useState(84);
    const [skip] = useState(0);
    

    useEffect(() => {
        if (isConnected) {
            subscribeGameHistory()
            getGameHistory(limit, skip)
        }
    }, [isConnected])

    useEffect(() => {
        if (isConnected) {
            getGameHistory(limit, skip)
        }
    }, [limit, skip, gameCondition])

    return (
        <div className="game-history">
            <div className="game-history-last-results">
                {history.slice(0, 10).map((item, index) => <GameHistoryLastResult round={item} key={index}/>)}
            </div>
            <GameHistoryHeader history={history} />
           <div className="game-history-data">
               <div className="info-box">
                   <div className="info-box-item"><FormattedMessage id="andar" /> <span className="divider">-</span> <span className="percent">{APercentage}%</span> </div>
                   <div className="info-box-item"><FormattedMessage id="bahar" /> <span className="divider">-</span> <span className="percent">{BPercentage}%</span></div>
               </div>
               <GameHistoryTable history={history} limit={limit}/>
           </div>
        </div>
    )
}

const mapDispatchToProps = (state) => {
    return {
        isConnected: state.globalInfo.isConnected,
        gameCondition: state.gameCondition,
        history: state.gameHistory.history,
        APercentage: state.gameHistory.APercentage,
        BPercentage: state.gameHistory.BPercentage,
        DrawPercentage: state.gameHistory.DrawPercentage
    }
}

const mapPropsToState = {
    subscribeGameHistory,
    getGameHistory
}

export default connect(mapDispatchToProps,mapPropsToState)(GameHistory);