import React, {useEffect} from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {BalanceIcon, TotalBetsIcon} from "@resource";
import {subscribeRoundIdAction} from "@actions/roundIdAction";
import UserInfo from "@components/userInfo";
import { abbrNum } from "@services/betService";
import {subBalanceUpdate} from "@actions/userActions"
import HeaderActions from "./headerActions";

function Header({roundId, subscribeRoundIdAction, userInfo, totalBets, subBalanceUpdate, minStake, maxStake}) {
        
    useEffect(() => {
        subscribeRoundIdAction()
        subBalanceUpdate()
    }, [])

    return (
    <header>  
        <div className="game-controller">
            <div className="info-box">
                <div className="game-info">
                    <p className="title"><FormattedMessage id="welcome_text"/></p>
                    <p className="description"> { roundId ? <><span>Round ID: </span>{roundId}</> : "Round ID: -" }</p>
                </div>
                <div className="min-max-stake">
                    <div className="min-max-stake-item"><FormattedMessage id="min"/>: <span>{abbrNum(minStake)}</span></div>
                    <span className="divider">-</span>
                    <div className="min-max-stake-item"><FormattedMessage id="max"/>: <span>{abbrNum(maxStake)}</span></div>
                </div>
                <div className="user-info">
                    <UserInfo title={<FormattedMessage id="balance"/>} value={userInfo.Balance || 0} icon={<BalanceIcon />} />
                    <UserInfo title={<FormattedMessage id="total_bet"/>} className="total-bets" value={(Number(totalBets.toFixed(2)) || 0)} icon={<TotalBetsIcon />} />
                </div>
            </div>
            <HeaderActions/>
        </div> 
    </header>)
}

function mapStateToProps(state) {
    return {
        roundId: state.roundId,
        totalBets: state.totalBets,
        userInfo: state.userInfo,
        minStake: state.limit.Min,
        maxStake: state.limit.Max
    }
}

const mapDispatchToProps = {
    subscribeRoundIdAction,
    subBalanceUpdate,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)